import {objectFind, objectFindUpdate} from "../../helpers/jsHelper";

const { createSlice } = require('@reduxjs/toolkit');

const searchSlice = new createSlice({
   name: 'search',
   initialState: {
      formData: {},
      loading: false,
      autoLoadMaster: {},
      searchTotalFound:0,
      searchData:[]
   },
   reducers: {
      searchAddAutoLoadMaster: (state, { payload }) => {
         state.autoLoadMaster = payload;
      },
      searchCommentUpdate: (state, { payload }) => {
         const { _id = null } = payload ?? {};
         if (_id) {
            const { commentCount } = objectFind(state.searchData, '_id', _id);
            state.searchData = objectFindUpdate(state.searchData, '_id', _id, { commentCount: commentCount + 1 });
         }
      },
      searchFormData: (state, { payload }) => {
         state.formData = Object.assign({}, state.formData, payload);
      },
      setSearchLoading:(state, { payload })=>{
         state.loading = payload
      },
      setSearchResults:(state, { payload })=>{
         state.searchTotalFound = payload.totalFound;
         state.searchData = payload.data
      }
   },
});

export const { searchAddAutoLoadMaster,searchFormData,setSearchResults,setSearchLoading,searchCommentUpdate } = searchSlice.actions;
export const searchSelector = state => state.search;
const searchSlicer = searchSlice.reducer;
export default searchSlicer;
