export const HOME = '/';

//hiddenMenu
export const ROUTE_PROFILE = '/profile';
export const ROUTE_CANDIDATE = '/candidate';
export const ROUTE_CANDIDATE_EDIT = '/candidate/edit';
export const ROUTE_CANDIDATE_VIEW = '/candidate/view';
export const ROUTE_RESUME_VIEW = '/resume';
export const UPLOAD_VIEW = '/upload';
export const ROUTE_SEARCH = '/search';

//error menu
export const ROUTE_NO_INTERNET = '/server-disconnected';
export const ROUTE_404 = '404';

//sidebar Menu
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_TASK = '/task';
export const ROUTE_CHECKLIST = '/checklist';
export const ROUTE_JOBS = '/jobs';
export const ROUTE_MASTER = '/master';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_EMPLOYEE = '/employees';
