import { useDispatch, useSelector } from 'react-redux';
import MuAlert from '../basic/alert.js';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { activitySelector, alertClose, navigate, snackClose } from '../../redux/slicer/activitySlice.js';
import { Route, Switch, useHistory } from 'react-router-dom';
import errorMenuList from '../../config/menu/errorMenu.js';

export default function ActivityContainer (props) {
   const history = useHistory();
   const { enqueueSnackbar } = useSnackbar();
   const { navigatePath, alertOpen, alertTitle, alertText, alertType, snackMsg, snackPos, snackVariant } = useSelector(activitySelector);
   const dispatch = useDispatch();
   const handleClose = () => {dispatch(alertClose())};
   
   useEffect(() => {
      if (snackMsg) {
         enqueueSnackbar(snackMsg, {
            variant: snackVariant,
            preventDuplicate: false,
            anchorOrigin: {
               horizontal: snackPos,
               vertical: 'bottom',
            },
            onClose: () => {
               dispatch(snackClose());
            },
         });
      }
   }, [snackMsg]);
   
   useEffect(() => {
      if (navigatePath) {
         history.push(navigatePath);
         dispatch(navigate(null));
      }
   }, [navigatePath]);
   
   return (
     <>
        <Switch>
           {errorMenuList && errorMenuList.map(({path,component,exact})=>(
             <Route key={path} path={path} exact={exact} component={component}/>
           ))}
           <Route key={"otherRoutes"}>
              <MuAlert open={alertOpen} type={alertType} title={alertTitle} close={handleClose} text={alertText}/>
              {props.children}
           </Route>
        </Switch>
     </>
   );
}
