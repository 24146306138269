import { Avatar, Breadcrumbs, Button, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import { Suspense, useEffect } from 'react';
import { LazyLoader } from './loader';
import useComponentStyle from '../../assets/material-ui/component/componentStyle.js';
import { Link } from 'react-router-dom';
import { BREAD_CRUMB_ICON } from '../../config/constant/projectConstant.js';
import { useDispatch } from 'react-redux';
import useMu404Style from '../../assets/material-ui/pages/notFoundStyle.js';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { NOT_FOUND_IMAGE } from '../../config/constant/imageConstant.js';
import { grey } from '@material-ui/core/colors';
import { sideBarStatus } from '../../redux/slicer/adminSlice.js';

export function MuTooltipIconButton ({
   title = '',
   color = 'inherit',
   to = '',
   href = null,
   target = null,
   className = null,
   children = null,
   download = null,
   bgColor = null,
   iconColor=null,
   ...restProps
}) {
   return (
     <Tooltip title={title}>
        {download ?
          <IconButton color={color} className={className} style={{ backgroundColor: bgColor }} component={'a'} download={download} href={to} {...restProps}>{children}</IconButton> :(
              href ? <IconButton color={color} target={target} className={className} style={{ backgroundColor: bgColor,color:iconColor }} component={'a'} href={href} {...restProps}>{children}</IconButton> :
                     <IconButton color={color} target={target} className={className} style={{ backgroundColor: bgColor,color:iconColor }} component={to ? Link : 'span'} to={to} {...restProps}>{children}</IconButton>
          )}
     </Tooltip>
   );
}

export function AvatarIcon ({ onClick = null, color = grey[500], size = 30, ...props }) {
   return (
     <Avatar className="pointer" onClick={onClick}
             style={{ backgroundColor: color, width: size, height: size }}>{props.children}</Avatar>
   );
}

export function LazyComponent (props) {
   return (
     <Suspense fallback={<LazyLoader/>}>
        {props.children}
     </Suspense>
   );
}

export function MuBreadCrumbs ({ list }) {
   const classes = useComponentStyle();
   return (
     <>
        <br/>
        <Breadcrumbs component={Paper} elevation={0} separator={BREAD_CRUMB_ICON}
                     className={classes.main}>
           {list && list.map(({ path = null, text = null }) => (
             text &&
             <Typography key={text} component={path && Link} className={classes.links} to={path} variant={'button'}
                         color="textSecondary">{text}</Typography>
           ))}
        </Breadcrumbs>
     </>
   );
}

export function MuSideBarStatus ({ open = false }) {
   const dispatch = useDispatch();
   useEffect(() => {
      if (!open) {
         dispatch(sideBarStatus(false));
      } else {
         dispatch(sideBarStatus(true));
      }
   }, [dispatch, open]);
   return null;
}

export function MuNoUserFound () {
   const classes = useMu404Style();
   return (
     <div className={classes.root}>
        <Grid container direction="column" className="animated fadeIn" justify="center" alignContent="center">
           <Grid item>
              <br/><img src={NOT_FOUND_IMAGE} alt="notfound" width={400}/><br/><br/>
              <Typography align="center" component={Grid}>
                 <Button color={'primary'} component={Link} disableElevation to={'/candidate'}
                         startIcon={<PersonAddIcon/>} variant={'contained'}>Create Candidate</Button>
              </Typography>
           </Grid>
        </Grid>
     </div>
   );
}

export function MuScrollView ({ height = 'inherit', width = 'inherit', children = null }) {
   return <div style={{ height, overflow: 'auto', width }}>{children}</div>;
}



