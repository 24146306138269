import { createSlice } from '@reduxjs/toolkit';

const sessionSlice = createSlice({
   name: 'session',
   initialState: {
      session: false,
      loading: true,
      sessionData: null,
      loginLoading: false,
   },
   reducers: {
      sessionSuccess: (state, { payload }) => {
         state.session = true;
         state.loading = false;
         state.sessionData = payload;
         state.loginLoading = false;
      },
      sessionFail: (state) => {
         state.session = false;
         state.loading = false;
         state.loginLoading = false;
      },
      loginLoading: (state, { payload }) => {
         state.loginLoading = payload;
      },
   },
});

export const { sessionSuccess, sessionFail, loginLoading } = sessionSlice.actions;
export const sessionSelector = state => state.session;
const sessionSlicer = sessionSlice.reducer;
export default sessionSlicer;
