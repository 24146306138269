import { makeStyles } from '@material-ui/core';

const useMu404Style = makeStyles(() => ({
   root: {
      display: 'flex',
      background: 'transparent',
      marginTop: '2%',
   },
   papper: {
      borderRadius: '15px',
      overflow: 'hidden',
   },
   marginLeft: {
      marginLeft: 10,
   },
   center: {
      textAlign: 'center',
   },
}), { index: 1 });

export default useMu404Style;
