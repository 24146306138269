import { Button, Grid, Typography } from '@material-ui/core';
import useMu404Style from '../../../assets/material-ui/pages/notFoundStyle.js';
import { NO_INTERNET } from '../../../config/constant/imageConstant.js';
import ReplayIcon from '@material-ui/icons/Replay';
import { useDispatch } from 'react-redux';

export default function MuNoInternetFound () {
   const classes = useMu404Style();
   const dispatch = useDispatch();
   const handleClick = () => {
      window.location.href = '/';
   };
   return (
     <div className={classes.root}>
        <Grid container alignItems="center" direction="column" className="animated fadeIn" justify="center"
              alignContent="center">
           <Grid item className={classes.center}>
              <br/>
              <img src={NO_INTERNET} alt="no-internet-found" width={400}/>
              <br/>
              <Typography variant={'h6'} color={'textSecondary'}>Unable to connect server.<br/> Please check the
                 internet connection and try again</Typography>
              <br/>
              <Typography align="center" component={Grid}>
                 <Button color={'primary'} onClick={handleClick} startIcon={<ReplayIcon/>}
                         variant={'contained'}>Try again</Button>
              </Typography>
           </Grid>
        </Grid>
     </div>
   );
}
