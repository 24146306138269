import logo from '../../assets/images/logo.png';
import login_side from '../../assets/images/login-side-image.png';
import back_side from '../../assets/images/web_back.png';
import logo_icon from '../../assets/images/logoIcon.png';
import logo_white from '../../assets/images/logo_white.png';
import notfound_img from '../../assets/images/404.png';
import user_img from '../../assets/images/user.png';
import no_internet from '../../assets/images/no-internet.png';

export const MU_LOGO = logo;
export const MU_LOGIN_SIDE_IMAGE = login_side;
export const BG_IMAGE = back_side;
export const LOGO_ICON = logo_icon;
export const LOGO_WHITE = logo_white;
export const NOT_FOUND_IMAGE = notfound_img;
export const USER_IMG = user_img;
export const NO_INTERNET = no_internet;
