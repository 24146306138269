import { createSlice } from '@reduxjs/toolkit';
import sideBarMenu from '../../config/menu/sideBarMenu.js';
import { objectFind, objectFindUpdate } from '../../helpers/jsHelper.js';
import { resetMenu, resetSubmenu } from '../../helpers/componentHelper.js';

const adminSlice = createSlice({
   name: 'admin',
   initialState: {
      menu: sideBarMenu,
      open: false,
      breadCrumb: true,
      fullScreen:false,
   },
   reducers: {
      singleMenuInitial: (state, { payload }) => {
         state.menu = objectFindUpdate(state.menu, 'path', payload[0], { active: true });
      },
      multiMenuInitial: (state, { payload }) => {
         const { sub_menu = null } = objectFind(state.menu, 'pre_path', payload[0]);
         state.menu = objectFindUpdate(state.menu, 'pre_path', payload[0], {
            open: true,
            selected: true,
            sub_menu: objectFindUpdate(sub_menu, 'path', payload[1], { active: true }),
         });
      },
      menuCollapseUpdate: (state, { payload }) => {
         state.menu.forEach((a, ind) => {
            if (!a.active) {
               a['open'] = ind === payload.index ? payload.open : false;
            }
         });
      },
      singleMenuClick: (state, { payload }) => {
         state.menu = resetMenu([...state.menu]);
         state.menu.forEach((a, ind) => {
            a['active'] = ind === payload.index;
         });
      },
      multiMenuClick: (state, { payload }) => {
         state.menu.forEach((a, ind) => {
            a['open'] = ind === payload.index;
            a['selected'] = ind === payload.index;
            a['active'] = ind === payload.index;
            if (a.sub_menu) {
               if (ind === payload.index) {
                  a.sub_menu.forEach((sub, subInd) => {
                     sub['active'] = subInd === payload.subIndex;
                  });
               } else {
                  a.sub_menu = resetSubmenu(a.sub_menu);
               }
            }
         });
      },
      sideBarStatus: (state, { payload = true }) => {
         state.open = payload;
      },
      breadCrumbStatus: (state, { payload = true }) => {
         state.breadCrumb = payload;
      },
      fullScreen: (state, { payload })=>{
         state.fullScreen = payload;
      }
   },
});

export const {
   singleMenuInitial,
   multiMenuInitial,
   multiMenuClick,
   singleMenuClick,
   breadCrumbStatus,
   menuCollapseUpdate,
   sideBarStatus,
   fullScreen
} = adminSlice.actions;

export const adminSelector = (state) => state.admin;
const adminSlicer = adminSlice.reducer;
export default adminSlicer;


