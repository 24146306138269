import { Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useMu404Style from '../../../assets/material-ui/pages/notFoundStyle.js';
import { NOT_FOUND_IMAGE } from '../../../config/constant/imageConstant.js';
import ReplayIcon from '@material-ui/icons/Replay';
import HomeIcon from '@material-ui/icons/Home';
import { reload } from '../../../helpers/jsHelper.js';

export default function MuNotFound () {
   const classes = useMu404Style();
   return (
     <div className={classes.root}>
        <Grid container direction="column" className="animated fadeIn" justify="center" alignContent="center">
           <Grid item>
              <br/>
              <img src={NOT_FOUND_IMAGE} alt="notfound" width={400}/>
              <br/><br/>
              <Typography align="center" component={Grid}>
                 <Button color={'primary'} onClick={reload} startIcon={<ReplayIcon/>}
                         variant={'contained'}>Reload</Button>
                 <Button component={Link} to="/" disableElevation startIcon={<HomeIcon/>}
                         className={classes.marginLeft} variant={'outlined'}>Go to Home</Button>
              </Typography>
           </Grid>
        </Grid>
     </div>
   );
}
