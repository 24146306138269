import { ROUTE_404, ROUTE_NO_INTERNET } from '../constant/routeConstant.js';
import MuNoInternetFound from '../../components/global/notFound/noInternet.js';
import MuNotFound from '../../components/global/notFound/404.js';

const errorMenuList = [
   {
      name: 'No Internet',
      path: ROUTE_NO_INTERNET,
      sidebar: false,
      component: () => <MuNoInternetFound/>,
   },
   {
      name: '404',
      path: ROUTE_404,
      component: (a) => <MuNotFound {...a}/>,
   },
].map(a => ({ ...a, prevent_menu: true }));

export default errorMenuList;
