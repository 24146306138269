import { gql } from '@apollo/client';

export const CREATE_LOGIN = gql`
mutation createLogin($username:String!,$password:String!,){
  result:createLogin(username:$username,password:$password){
    userData{
      _id
      name
      email
    }
    token
  }
}`;

export const GET_SESSION = gql`query{
  result:getSession{
     userData{
      _id
      name
      email
    }
  }
}`;
