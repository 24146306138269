import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Grid, Typography } from '@material-ui/core';
import useComponentStyle from '../../assets/material-ui/component/componentStyle.js';
import {
   CancelOutlined,
   CheckCircleOutlineRounded,
   CloudOffOutlined,
   ErrorOutlineRounded,
   InfoOutlined,
   SignalWifiOffOutlined,
} from '@material-ui/icons';
import useColorStyle from '../../assets/material-ui/component/colorStyle.js';
import clsx from 'clsx';

export default function MuAlert ({
   type = 'error',
   open = false,
   close = null,
   title = null,
   text = null,
   onClose = null,
   onOpen = null,
   size = 'xs',
   ...props
}) {
   const classes = useComponentStyle();
   const colorStyle = useColorStyle();
   
   const typeIcons = {
      success: (className) => <CheckCircleOutlineRounded className={className}/>,
      warning: (className) => <ErrorOutlineRounded className={className}/>,
      error: (className) => <CancelOutlined className={className}/>,
      info: (className) => <InfoOutlined className={className}/>,
      internet: (className) => <SignalWifiOffOutlined className={className}/>,
      server: (className) => <CloudOffOutlined className={className}/>,
   };
   
   return (
     <div>
        <Dialog maxWidth={size} classes={{ paper: classes.paper }} open={open} onClose={close} fullWidth>
           <DialogTitle>
              <Grid container alignItems="center" direction="column" className="animated fadeIn" justify="center"
                    alignContent="center">
                 <Grid item className={classes.center}>
                    {typeIcons[type]?.call(typeIcons, clsx(classes.alertIcon, colorStyle[type]))}
                    <Typography variant={'h5'}>{title}</Typography>
                    <Typography variant={'subtitle1'} className={classes.marginTb}
                                color={'textSecondary'}>{text}</Typography>
                 </Grid>
              </Grid>
           </DialogTitle>
           <DialogActions>
              <Button autoFocus size={'medium'} className={classes.buttonFoused} variant={'contained'} color={'primary'}
                      onClick={close}>ok</Button>
           </DialogActions>
        </Dialog>
     </div>
   );
}
