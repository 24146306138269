import { amber, brown, green, grey, orange, red, teal, yellow } from '@material-ui/core/colors';
import {
   ArrowRightRounded,
   CheckRounded,
   ChevronRightRounded,
   DoneRounded,
   HomeRounded,
   KeyboardRounded,
   PersonRounded,
   PhoneCallbackRounded,
   PhoneMissedRounded,
   PowerSettingsNewRounded,
   RingVolumeRounded,
   SentimentVerySatisfiedRounded,
   SettingsRounded,
   SpeakerNotesRounded,
} from '@material-ui/icons';

export const TITLE_PREFIX = 'Beerana Portal';
export const MENU_DEFAULT_ICON = <ArrowRightRounded fontSize={'small'}/>;
export const BREAD_CRUMB_ICON = <ChevronRightRounded fontSize={'small'}/>;
export const DRAWER_WIDTH = 200;
export const CANDIDATE_CARD_HEIGHT = 320;
export const SEARCH_LENGTH_PER_PAGE = 50;
export const MU_GENDER = [null, 'Male', 'Female'];
export const MU_PROSPECT = [null, 'Prospect', 'Non Prospect'];
export const MU_ROLE = [null, 'Admin', 'Team leader', 'Recruiter'];
export const DEFAULT_STICKY_TOP = 70; //AppBar header height

export const MU_COMMENT_TYPE = [
   { text: 'Not Respond', value: 1, icon: 'phone_missed', color: grey[500] },
   { text: 'Switch off', value: 2, icon: 'phone_disabled', color: red[500] },
   { text: 'Call Latter', value: 3, icon: 'query_builder', color: yellow[500] },
   { text: 'Ringing', value: 4, icon: 'notifications_active', color: orange[500] },
   { text: 'Reached', value: 5, icon: 'check_circle', color: green[500] },
];

export const MU_PROSPECT_BOOLEAN = [{text:'Prospect',value:true}, { text:'Non Prospect',value:false}];


export const MENU_ICON = {
   home: <HomeRounded fontSize={'small'}/>,
   task: <RingVolumeRounded fontSize={'small'}/>,
   check: <CheckRounded fontSize={'small'}/>,
   master: <KeyboardRounded fontSize={'small'}/>,
   job: <PersonRounded fontSize={'small'}/>,
   settings: <SettingsRounded fontSize={'small'}/>,
   employee: <SentimentVerySatisfiedRounded fontSize={'small'}/>,
};

export const COMMENT_STATUS_ICON = [
   { icon: <SpeakerNotesRounded fontSize={'small'}/>, color: grey[400],title:null},
   { icon: <PhoneMissedRounded fontSize={'small'}/>, color: red[500],title:'Not Respond' },
   { icon: <PowerSettingsNewRounded fontSize={'small'}/>, color: amber[500],title:'Switch off' },
   { icon: <PhoneCallbackRounded fontSize={'small'}/>, color: teal[500],title:'Call Latter' },
   { icon: <RingVolumeRounded fontSize={'small'}/>, color: brown[500] ,title:'Ringing'},
   { icon: <DoneRounded fontSize={'small'}/>, color: green[500] ,title:'Reached'},
];

export const WHATS_APP_URL = 'https://web.whatsapp.com/'
