import store from '../redux/store.js';
import { capitalize } from './jsHelper.js';
import { dispatcher } from './projectHelper.js';
import { sideBarStatus } from '../redux/slicer/adminSlice.js';
import { alertOpen, snackShow } from '../redux/slicer/activitySlice.js';

export const classNames = (data = []) => data && data.map(a => `${a} `).join(' ');

export const combineKey = (key) => key.replace(' ', '_');

export const resetSubmenu = (menu) => menu && menu.map(a => ({ ...a, active: false }));

export const resetMenu = (menu) => menu.map(({ sub_menu = null, ...a }) => ({
   ...a,
   selected: false,
   open: false,
   active: false,
   sub_menu: resetSubmenu(sub_menu),
}));

export const slashAdd = name => name.flatMap(a => a ? `/${a}` : []);

export const routeChange = (data) => {
   const { admin: { open } } = store.getState();
   if (data?.sidebar === undefined && !open) {
      dispatcher(sideBarStatus(true));
   }
};

export const successAlert = ({ title = null, text = null }) => {
   return dispatcher(alertOpen({
      type: 'success', title: capitalize(title), text,
   }));
};

export const failureAlert = ({ title = null, text = null }) => {
   return dispatcher(alertOpen({
      type: 'error', title: capitalize(title), text,
   }));
};
export const warningAlert = ({ title = null, text = null }) => {
   return dispatcher(alertOpen({
      type: 'warning', title: capitalize(title), text,
   }));
};
export const infoAlert = ({ title = null, text = null }) => {
   return dispatcher(alertOpen({
      type: 'info', title: capitalize(title), text,
   }));
};

export const serverAlert = ({ title = null, text = null }) => {
   return dispatcher(alertOpen({
      type: 'server', title: capitalize(title), text,
   }));
};

export const snack = (msg = null, pos = 'left', variant = 'default') => {
   return msg && dispatcher(snackShow({
      msg, pos, variant,
   }));
};

export const successSnack = (msg = null, pos = 'left') => snack(msg, pos, 'success');
export const errorSnack = (msg = null, pos = 'left') => snack(msg, pos, 'error');
export const infoSnack = (msg = null, pos = 'left') => snack(msg, pos, 'info');
export const warningSnack = (msg = null, pos = 'left') => snack(msg, pos, 'warning');

export const snackUpsertResponse = (result={}) => {
   if (result?.affectedStatus) {
      if (result.affectedStatus.insertId) {
         successSnack('Created Successfully');
      } else if (result.affectedStatus.nModified) {
         successSnack('Updated Successfully');
      } else {
         warningSnack('No changes found');
      }
   }
};
