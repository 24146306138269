import { makeStyles } from '@material-ui/core';

const useLogoLoader = makeStyles((theme) => ({
   root: {
      display: 'flex',
      minHeight: '100vh',
      backgroundColor: '#fff',
   },
   logo: {
      width: 200,
   },
   fullPageLoader: {
      minHeight: '80vh',
      display: 'flex',
      width: '100%',
   },
   fullSectionLoader: {
      backgroundColor: 'rgba(255,255,255,0.64)',
      minHeight: '80vh',
      display: 'flex',
      width: '100%',
   },
   lazyLoader: {
      minHeight: '70vh',
      display: 'flex',
   },
   viewLoad: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: 0,
      zIndex: 1,
      backgroundColor: 'rgba(255,255,255,0.64)',
      margin: -8,
      textAlign: 'center',
   },
   linearLoader: {
      width: 70,
      textAlign: 'center',
      display: 'block',
      margin: '20px auto',
   },
}), { index: 1 });

export default useLogoLoader;
