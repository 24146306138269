import store from '../redux/store.js';
import { warningAlert } from './componentHelper.js';
import { sessionFail } from '../redux/slicer/sessionSlice.js';
import moment from 'moment';
import { REACT_APP_API_HOST } from '../config/constant/apiConstant.js';
import { UPLOAD_VIEW } from '../config/constant/routeConstant.js';
import {WHATS_APP_URL} from "../config/constant/projectConstant";

export const devConsoleLog = (...a) => {
   if (a) {
      if (isDev()) {
         console.log(...a);
      }
   }
};

export const isDev = () => {
   return process.env.NODE_ENV === 'development';
};

let preStore = {};

export const dispatcher = (a) => {
      store.dispatch(a);
};

export const storeToken = (token) => {
   window.localStorage.setItem('token', token);
};

export const localSyntaxError = () => {
   warningAlert({ title: 'Something Wrong', text: 'Please try again still continues contact admin' });
};

export const getToken = (token = 'token') => window.localStorage.getItem(token);
export const removeToken = (token = 'token') => window.localStorage.removeItem(token);

export const sessionDestroy = () => {
   removeToken();
   dispatcher(sessionFail());
};

export const uploadPathBuilder = (a) => {
   return REACT_APP_API_HOST + a;
};

export const mutationParams = (arr) => {
   const variables = Object.entries(arr).map(([k, v]) => `$${k}:${v}`).join(',').toString();
   const params = Object.keys(arr).map(a => `${a}:$${a}`).join(',').toString();
   return [variables, params];
};

export function ageCalc (d) {
   return moment(new Date()).diff(moment(d), 'years');
}

export const autoCompleteCleanup = (a) => {
   Object.keys(a).forEach(key => {
      if (Array.isArray(a[key])) {
         a[key] = a[key].map(i => i?.['text'] || i);
      }
   });
   return a;
};

export const labelCreator = (arr)=> (arr ?? []).map(label=>({label}))
export const whatsappUrl = (num='',msg='')=> {
   num = num.replace(/\)|\(|\s+|\+/g,'');
   return `${WHATS_APP_URL}send?phone=+91${num}&text=${msg}&app_absent=0`
}
export const uploadPreview = (a) => REACT_APP_API_HOST+UPLOAD_VIEW+'/'+a
