import { warningAlert } from '../../helpers/componentHelper.js';
import { getToken, removeToken, storeToken } from '../../helpers/projectHelper.js';
import { loginLoading, sessionFail, sessionSuccess } from '../../redux/slicer/sessionSlice.js';
import { apolloClient } from '../../config/others/apollo.js';
import { CREATE_LOGIN, GET_SESSION } from '../graphQL/session.js';

export const sessionCheckApi = () => (dispatch) => {
   if (!getToken()) {
      dispatch(sessionFail());
      return;
   }
   apolloClient.query({ query: GET_SESSION }).then(({ data: { result } }) => {
      if (!result) {
         dispatch(sessionFail());
         removeToken()
         return;
      }
      dispatch(sessionSuccess(result?.userData ?? null));
   }).catch(e => {
      dispatch(sessionFail());
      removeToken()
   });
   
};

export const createLoginApi = (variables) => (dispatch) => {
   dispatch(loginLoading(true));
   apolloClient.mutate({ mutation: CREATE_LOGIN, variables }).then(({ data: { result } }) => {
      if (!result) {
         warningAlert({
            title: 'Login failed',
            text: 'invalid username/password',
         });
         dispatch(loginLoading(false));
         return;
      }
      dispatch(sessionSuccess(result?.userData ?? null));
      storeToken(result?.token);
   }).catch(e => {
      dispatch(loginLoading(false));
   });
};
