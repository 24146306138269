import { CircularProgress, Grid, LinearProgress, Paper } from '@material-ui/core';
import useLogoLoader from '../../assets/material-ui/component/loaderStyle.js';
import { MU_LOGO } from '../../config/constant/imageConstant.js';

export default function FullScreenLogoLoader () {
   const classes = useLogoLoader();
   
   return (
     <div className={classes.root}>
        <Grid container direction="column" alignContent="center" justify="center">
           <img src={MU_LOGO} alt="logo" className={classes.logo}/>
           <LinearProgress color="primary" className={classes.linearLoader}/>
        </Grid>
     </div>
   );
}

export function FullSectionLoader () {
   const classes = useLogoLoader();
   
   return (
     <div className={classes.fullSectionLoader}>
        <Grid container direction="column" alignContent="center" justify="center">
           <CircularProgress color="primary" className={classes.linearLoader}/>
        </Grid>
     </div>
   );
}


export function FullViewLoader () {
   const classes = useLogoLoader();
   
   return (
     <Paper fullWidth elevation={0} className={classes.viewLoad}>
        <Grid container className="full-height" direction="column" alignContent="center" justify="center">
           <CircularProgress color="primary" className={classes.linearLoader}/>
        </Grid>
     </Paper>
   );
}

export function LazyLoader () {
   const classes = useLogoLoader();
   return (
     <div className={classes.lazyLoader}>
        <Grid container direction="column" alignContent="center" justify="center">
           <CircularProgress size={40}/>
        </Grid>
     </div>
   );
}

