import { responsiveFontSizes, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { LOGO_COLOR_GREEN } from '../../config/constant/colorConstant.js';

const MuTheme = createMuiTheme({
   palette: {
      primary: {
         main: LOGO_COLOR_GREEN,
         dark: LOGO_COLOR_GREEN,
         light: LOGO_COLOR_GREEN,
      },
   },
   typography: {
      htmlFontSize: 18,
      button: {
         textTransform: 'capitalize',
         fontSize: '0.8rem',
      },
   },
});
responsiveFontSizes(MuTheme);
export default MuTheme;
