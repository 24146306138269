import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getToken } from '../../helpers/projectHelper.js';
import { GRAPHQL, REACT_APP_API_HOST } from '../constant/apiConstant.js';
import { serverAlert, warningAlert } from '../../helpers/componentHelper.js';

const httpLink = new createHttpLink({ uri: `${REACT_APP_API_HOST}${GRAPHQL}` });
const authLink = setContext((_, { headers }) => {
   const token = getToken();
   return {
      headers: {
         ...headers,
         authorization: token ? `Bearer ${token}` : '',
      },
   };
});

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
   if (graphQLErrors) {
      const errData = graphQLErrors[0]?.errorMsg ? graphQLErrors[0] : graphQLErrors[1];
      const { errorMsg: title, errorData: text } = errData;
      warningAlert({ title, text });
   }else if (networkError) {
      serverAlert({
         title: 'Connection Failure',
         text: 'Please check you internet and try once again. Still continues please contact admin',
      });
      
   }
});

export const apolloClient = new ApolloClient({
   link: from([errorLink, authLink, httpLink]),
   cache: new InMemoryCache({
      addTypename: false,
      typePolicies: 'network-only',
   }),
   defaultOptions: {
      query: {
         fetchPolicy: 'no-cache',
         errorPolicy: 'all',
      },
   },
});
