import { Button, CircularProgress } from '@material-ui/core';

export function MuLoadingButton ({ loading, ...props }) {
   return (
     <Button {...props}  disabled={loading}>
        {loading && <CircularProgress size={20} thickness={5} color="primary"/>}
        {!loading && props.children}
     </Button>
   );
}
