import { makeStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { LOGO_COLOR_GREEN } from '../../../config/constant/colorConstant.js';

const useComponentStyle = makeStyles((theme) => ({
   main: {
      padding: theme.spacing(0.3, 2),
      borderRadius: 50,
      backgroundColor: grey[50],
   },
   contentEditable: {
      fontSize: 15,
      height: theme.spacing(5),
      overflow: 'hidden',
      padding: theme.spacing(1.5, 1.5),
      border: `1px solid ${theme.palette.primary.main}`,
      fontFamily: theme.typography.fontFamily,
      margin: theme.spacing(2, 0),
      borderRadius: 3,
   },
   borderB:{
      borderBottom:'1px solid #ececec'
   },
   zeroRadius:{
     borderRadius:"0px !important"
   },
   skeleton: {
      transform: 'initial',
      marginBottom: theme.spacing(3),
      borderRadius:theme.spacing(3)
   },
   flex: {
      flex: 1,
   },
   dialogContent: {
      overflowY: 'initial',
   },
   fullWidth: {
      width: '100%',
   },
   pullRight: {
      float: 'right',
   },
   avatarPrimary: {
      backgroundColor: theme.palette.primary.main,
   },
   avatarSecondary: {
      backgroundColor: theme.palette.secondary.main,
   },
   avatarLarge: {
      height: theme.spacing(13),
      width: theme.spacing(13),
      margin: 'auto',
   },
   avatarLarger: {
      height: theme.spacing(18),
      width: theme.spacing(18),
      margin: 'auto',
   },
   loaderParent: {
      position: 'relative',
   },
   links: {
      textDecoration: 'none',
      '&:hover': {
         textDecoration: 'underline',
      },
   },
   buttonFoused: {
      '&:focus': {
         border: `3px solid ${green[300]}`,
      },
   },
   horizontalTab: {
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: grey[300],
      paddingTop: theme.spacing(1),
   },
   inputLabel: {
      fontSize: 14,
   },
   overFlowHide: {
      overflow: 'hidden',
   },
   overflow: {
      overflow: 'auto',
   },
   center: {
      textAlign: 'center',
   },
   select: {
      marginTop: 0,
      width: '100%',
   },
   datePicker: {
      marginTop: -3,
   },
   marginNull: {
      marginTop:"-3px !important",
   },
   marginTb: {
      margin: theme.spacing(1.5, 0),
   },
   marginT: {
      marginTop: theme.spacing(0.5),
   },
   marginTMidLong: {
      marginTop: theme.spacing(2),
   },
   paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1),
      borderRadius: 15,
   },
   modalPaper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(1, 1),
      borderRadius: 15,
   },
   paddingLeft: {
      paddingLeft: 23,
   },
   listBox: {
      backgroundColor: 'rgb(0 0 0 / 3%)',
      borderRadius: 15,
      padding: theme.spacing(1, 2),
   },
   secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
   },
   alignLeft: {
      '&:before': {
         flex: 'initial !important',
         padding: '1px !important',
      },
   },
   list: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
   small: {
      height: theme.spacing(2.8),
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.button.fontWeight,
      backgroundColor: LOGO_COLOR_GREEN,
   },
   listItem: {
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(1, 0.1),
   },
   listItemIcon: {
      minWidth: 35,
   },
   marginReverse: {
      marginTop: -38,
   },
   marginTopZero:{
      marginTop:'0px !important'
   },
   drawer: {
      width: theme.spacing(55),
   },
   positionSticky: {
      top: 0,
      left: 'auto',
      right: 0,
      position: 'sticky',
      zIndex: 100,
   },
   iconColor: {
      color: grey[600],
   },
   appBarTop: {
      top: '70px !important',
   },
   bold: {
      fontWeight: 600,
   },
   marginLR: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
   },
   marginLeftLong: {
      marginLeft: theme.spacing(2),
   },
   marginTopBottom: {
      margin: theme.spacing(0.8, 0),
   },
   marginTbLong: {
      margin: theme.spacing(3, 0),
   },
   marginMedLong: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
   },
   padding: {
      padding: theme.spacing(0, 1),
   },
   nowrap: {
      flexWrap: 'nowrap',
   },
   vAlignBottom: {
      verticalAlign: 'text-bottom',
   },
   iconColorPrimary: {
      color: grey[50],
   },
   borderRight: {
      borderRight: 1,
      borderRightColor: grey[300],
      borderRightStyle: 'solid',
   },
   marginB: {
      marginBottom: theme.spacing(2),
   },
   iframe: {
      width: '100%',
      height: 800,
      margin: 0,
      border: 'none',
   },
   spaceBetween: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   alertIcon: {
      fontSize: theme.spacing(15),
      marginBottom: theme.spacing(1),
   },
   white: {
      backgroundColor: theme.palette.background.paper,
   },
}), { index: 1 });

export default useComponentStyle;
