import { makeStyles } from '@material-ui/core';
import { blue, green, orange, red } from '@material-ui/core/colors';

const useColorStyle = makeStyles((theme) => ({
   success: {
      color: green[500],
   },
   error: {
      color: red[500],
   },
   warning: {
      color: orange[500],
   },
   info: {
      color: blue[500],
   },
}), { index: 1 });

export default useColorStyle;
