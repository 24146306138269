import { TITLE_PREFIX } from '../config/constant/projectConstant.js';
import moment from 'moment';

export const setTitle = title => {
   if (title) {
      document.title = title + ' - ' + TITLE_PREFIX;
   }
};
export const arrayRemoveIndex = (arr, ind) => arr.filter((_, index) => index !== ind);
export const arrayRemove = (arr, key, val) => arr.filter(a => a[key] !== val);

export const reload = () => window.location.reload();

export const parseFloatNaN = a => isNaN(a) || a === '' ? 0 : parseFloat(a);

export const spaceReduce = (a) => a ? a.split(' ').join('_') : '';

export const objectFind = (a, key, select) => {
   const res = a.find(i => i[key] === select);
   if (res) {
      return res;
   }
   return {};
};

export const objectGetValue = (a, key, select,value) => {
   const res = a.find(i => i[key] === select);
   if (res) {
      return res?.[value] ?? null;
   }
   return null;
};


export const objectFindCheck = (a, key, select) => a.findIndex(i => i[key] === select) > -1;

export const objectFindUpdate = (a = null, key, select, res) => {
   if (!a) {
      return null;
   }
   let arr = [...a];
   let ind = arr.findIndex(i => i[key] === select);
   arr[ind] = { ...arr[ind], ...res };
   return [...arr];
};

export const arrayUpsert = (data = [], key, value, obj) => {
   let newData = data;
   if (objectFindCheck(data, key, value)) {
      newData = objectFindUpdate(data, key, value, obj);
   } else {
      newData.unshift(obj);
   }
   return newData;
};
export const lookupArray = array => Object.assign({}, ...array.map(i => ({ [i.value]: i.text })));

export const optionArray = (array, val, text) => array.map(i => ({ value: i[val], text: i[text], ...i }));

export const arrayToOption = (arr = []) => arr.flatMap((val, ind) => (val ? { text: val, value: ind } : []));
export const optionToArray = (arr=[],key='') => arr.map(a=>a[key])
export const timeRender = (a = null) => a ? moment(a).format('DD-MMM-YYYY hh:mm A') : null;

export const capitalize = a => a ? a.toString().split('')[0].toUpperCase() + a.slice(1) : '';

export const dateRender = a => moment(a).format('DD-MM-YYYY');

export const avatarLetter = (a) => a ? a.toString().slice(0, 2).toUpperCase() : '';

export const updateToArrayLoop = (a = [], obj) => a.map(i => Object.assign(i, obj));
export const freezeClone = (a = {}) => JSON.parse(JSON.stringify(a));
export const sum = (a, key) => (a.reduce((acc, b) => {
   if (b[key]) {
      acc = acc + parseFloatNaN(b[key]);
   }
   return acc;
}, 0)).toFixed(2);

export const href = link => {
   window.open(link);
};
export const hashOpen = (link = null) => {
   window.open(`#${link}`);
};

export const scrollToBottom = (elem) => {
   if (elem?.scrollHeight) {
      elem.scrollTop = elem.scrollHeight;
   }
};

export const duplicateRemove = (arr, key) => {
   return arr.map(a => a[key])
     .filter((a, ind, ar) => ind === ar.indexOf(a))
     .reduce((acc, curr) => (acc.push({ [key]: curr }), acc), []);
};

export const NumGen = (length) => Array.from(Array(length), (_, ind) => ind);

export const timeRandomName = (a = 'upload') => {
   const suf = moment().format('DD_MM_YYYY_HH_mm_SS') + Math.round(Math.random() * 100000000).toString();
   return a + '_' + suf;
};

