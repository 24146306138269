import '../src/assets/css/style.css';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import store, { persist } from './redux/store';
import AppContainer from './pages';
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import MuTheme from './assets/material-ui/theme.js';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './config/others/apollo.js';
import { PersistGate } from 'redux-persist/integration/react';

function App () {
   return (
     <ApolloProvider client={apolloClient}>
        <MuiThemeProvider theme={MuTheme}>
           <SnackbarProvider maxSnack={3} preventDuplicate={false} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
              <Provider store={store}>
                 <PersistGate loading={null} persistor={persist}>
                    <Router>
                       <AppContainer/>
                    </Router>
                 </PersistGate>
              </Provider>
           </SnackbarProvider>
        </MuiThemeProvider>
     </ApolloProvider>
   );
}

export default App;
