import {lazy} from 'react';
import {
   ROUTE_CHECKLIST,
   ROUTE_DASHBOARD,
   ROUTE_EMPLOYEE,
   ROUTE_JOBS,
   ROUTE_MASTER,
   ROUTE_SETTINGS,
   ROUTE_TASK,
} from '../constant/routeConstant.js';

const MuMasterNotice = lazy(() => import('../../pages/admin/master/noticePeriod.js'));
const MuTaskCalling = lazy(() => import('../../pages/admin/taskManager/calling'));
const MuAdminSettings = lazy(() => import('../../pages/admin/settings/settings'));
const MuAdminDashboard = lazy(() => import('../../pages/admin/dashboard/adminRole.js'));
const MuJobs = lazy(() => import('../../pages/admin/jobs/jobs'));
const MuEmployees = lazy(() => import('../../pages/admin/employee/employee'));
const MuMasterSkills = lazy(() => import('../../pages/admin/master/skills.js'));
const MuMasterReference = lazy(() => import('../../pages/admin/master/reference.js'));
const MuMasterLevel = lazy(() => import('../../pages/admin/master/level.js'));
const MuMasterChannel = lazy(() => import('../../pages/admin/master/channel.js'));
const MuMasterTags = lazy(() => import('../../pages/admin/master/tag.js'));
const MuMasterClient = lazy(() => import('../../pages/admin/master/client.js'));
const MuMasterDocument = lazy(() => import('../../pages/admin/master/document.js'));
const MuMasterLocation = lazy(() => import('../../pages/admin/master/location.js'));
const MuMasterFunArea = lazy(() => import('../../pages/admin/master/funArea.js'));
const MuMasterIndustry = lazy(() => import('../../pages/admin/master/industry.js'));


const sideBarMenu = [
   {
      name: 'Dashboard',
      pre_path: ROUTE_DASHBOARD,
      icon: 'home',
      sub_menu: [
         {
            name: 'Admin',
            title: 'Admin Dashboard',
            path: '/admin',
            component: (a) => <MuAdminDashboard {...a} />,
         },
         {
            name: 'Team Leader',
            path: '/team-leader',
         },
         {
            name: 'Recruiter',
            path: 'recruiter',
         },
      ],
   },
   {
      name: 'Task Manager',
      pre_path: ROUTE_TASK,
      icon: 'task',
      sub_menu: [
         {
            name: 'Calling',
            path: '/calling',
            component: () => <MuTaskCalling/>,
         },
         {
            name: 'Sourcing',
            path: '/sourcing',
         },
         {
            name: 'Interview',
            path: '/interview',
         },
         {
            name: 'Selected',
            path: '/selected',
         },
         {
            name: 'Waiting offer',
            path: '/waiting',
         },
         {
            name: 'Joined',
            path: '/joined',
         },
         {
            name: 'Drop',
            path: '/drop',
         },
      ],
   },
   {
      name: 'Checklist',
      pre_path: ROUTE_CHECKLIST,
      icon: 'check',
      sub_menu: [
         {
            name: 'Verification',
            path: '/verification',
         },
      ],
   },
   {
      name: 'Jobs',
      path: ROUTE_JOBS,
      icon: 'job',
      component: (a) => <MuJobs {...a} />,
   },
   {
      name: 'Master',
      pre_path: ROUTE_MASTER,
      icon: 'master',
      sub_menu: [
         {
            name: 'Skills',
            path: '/skills',
            component: () => <MuMasterSkills/>,
         },
         {
            name: 'Reference',
            path: '/reference',
            component: () => <MuMasterReference/>,
         },
         {
            name: 'Functional',
            path: '/functional-area',
            component: () => <MuMasterFunArea/>,
         },
         {
            name: 'Industry',
            path: '/Industry',
            component: () => <MuMasterIndustry/>,
         },
         {
            name: 'Level',
            path: '/level',
            component: () => <MuMasterLevel/>,
         },
         {
            name: 'Channel',
            path: '/channel',
            component: () => <MuMasterChannel/>,
         },
         {
            name: 'Tag',
            path: '/tag',
            component: () => <MuMasterTags/>,
         },
         {
            name: 'Notice Period',
            path: '/notice',
            component: () => <MuMasterNotice/>,
         },
         {
            name: 'Client',
            path: '/client',
            component: () => <MuMasterClient/>,
         },
         {
            name: 'Document',
            path: '/document',
            component: () => <MuMasterDocument/>,
         },
         {
            name: 'Location',
            path: '/location',
            component: () => <MuMasterLocation/>,
         },
      ],
   },
   {
      name: 'Settings',
      pre_path: ROUTE_SETTINGS,
      icon: 'settings',
      sub_menu: [
         {
            name: 'Mail settings',
            path: '/mail',
            component: (a) => <MuAdminSettings {...a} />,
         },
         {
            name: 'Template',
            path: '/template',
         },
      ],
   },
   {
      name: 'Employees',
      icon: 'employee',
      path: ROUTE_EMPLOYEE,
      component: (a) => <MuEmployees {...a}/>,
   },
];

export default sideBarMenu;
