import { createSlice } from '@reduxjs/toolkit';

const activitySlice = createSlice({
   name: 'activity',
   initialState: {
      navigatePath: '',
      alertOpen: false,
      alertTitle: null,
      alertText: null,
      alertType: 'info',
      snackMsg: null,
      snackPos: 'left',
      snackVariant: 'default',
   },
   reducers: {
      navigate: (state, { payload }) => {
         state.navigatePath = payload
      },
      alertOpen: (state, { payload }) => {
         state.alertTitle = payload?.title;
         state.alertText = payload?.text;
         state.alertType = payload?.type;
         state.alertOpen = true;
      },
      alertClose: (state) => {
         state.alertTitle = false;
         state.alertText = null;
         state.alertType = null;
         state.alertOpen = false;
      },
      snackShow: (state, { payload }) => {
         state.snackMsg = payload?.msg ?? '';
         state.snackPos = payload?.pos ?? 'left';
         state.snackVariant = payload?.variant ?? 'default';
      },
      snackClose: (state, { payload }) => {
         state.snackMsg = '';
      },
   },
});

export const { navigate, alertClose, alertOpen, snackClose, snackShow } = activitySlice.actions;
export const activitySelector = state => state.activity;
export const activitySlicer = activitySlice.reducer;
export default activitySlicer;
