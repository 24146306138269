import { makeStyles } from '@material-ui/core';
import { DARK_BLUE } from '../../../config/constant/colorConstant.js';
import { BG_IMAGE } from '../../../config/constant/imageConstant.js';

const useMuLoginStyle = makeStyles((theme) => ({
   root: {
      display: 'flex',
      minHeight: '100vh',
      backgroundImage: `url(${BG_IMAGE})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
   },
   papper: {
      maxWidth: '800px',
      borderRadius: '15px',
      overflow: 'hidden',
   },
   gridPaper: {
      padding: theme.spacing(5),
   },
   logo: {
      width: 150,
      display: 'block',
      margin: 'auto',
   },
   input: {
      marginTop: theme.spacing(3),
   },
   loginSideBar: {
      backgroundColor: DARK_BLUE,
   },
   loginSideBarImage: {
      width: '100%',
   },
}), { index: 1 });

export default useMuLoginStyle;
