import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import sessionSlicer from './slicer/sessionSlice.js';
import activitySlicer from './slicer/activitySlice.js';
import adminSlicer from './slicer/adminSlice.js';
import {
   channelSlicer,
   clientSlicer,
   documentSlicer,
   employeeSlicer, funAreaSlicer, industrySlicer,
   levelSlicer, locationSlicer,
   noticeSlicer,
   referenceSlicer,
   skillSlicer,
   tagSlicer,
   userRoleSlicer,
} from './slicer/masterSlice.js';
import candidateSlicer from './slicer/candidateSlice.js';

import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import taskSlicer from './slicer/taskSlice.js';
import searchSlicer from './slicer/searchSlice.js';

const persistConfig = {
   key: 'root',
   version: 1,
   blacklist: ['activity', 'admin', 'session', 'candidate','search'],
   storage,
   
};
const rootReducer = combineReducers({
   session: sessionSlicer,
   activity: activitySlicer,
   admin: adminSlicer,
   employee: employeeSlicer,
   skillMaster: skillSlicer,
   reference: referenceSlicer,
   level: levelSlicer,
   channel: channelSlicer,
   tag: tagSlicer,
   funArea: funAreaSlicer,
   industry: industrySlicer,
   document: documentSlicer,
   client: clientSlicer,
   location: locationSlicer,
   candidate: candidateSlicer,
   task: taskSlicer,
   userRole: userRoleSlicer,
   notice: noticeSlicer,
   search: searchSlicer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
   reducer: persistedReducer,
   middleware: getDefaultMiddleware({
      serializableCheck: false
   }),
});

export const persist = persistStore(store);
export default store;
