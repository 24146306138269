import { Button, Checkbox, FormControlLabel, Grid, Paper, TextField } from '@material-ui/core';
import useMuLoginStyle from '../../assets/material-ui/pages/loginStyle.js';
import { MU_LOGIN_SIDE_IMAGE, MU_LOGO } from '../../config/constant/imageConstant.js';
import { MuLoadingButton } from '../../components/basic/button';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelector } from '../../redux/slicer/sessionSlice.js';
import { createLoginApi } from '../../api/request/sessionApi.js';

export default function MuLogin (props) {
   const { loginLoading } = useSelector(sessionSelector);
   const username = useRef();
   const password = useRef();
   const classes = useMuLoginStyle();
   const dispatch = useDispatch();
   const handleLogin = (e) => {
      e.preventDefault();
      dispatch(createLoginApi({
         username: username.current.value,
         password: password.current.value,
      }));
   };
   
   return (
     <div className={classes.root}>
        <Grid container direction="row" alignContent="center" className="animated fadeIn" justify="center">
           <Grid item xs md={6} sm={10}>
              <Paper className={classes.papper} elevation={6}>
                 <Grid container direction="row" alignContent="stretch">
                    <Grid item xs={12} md={6} sm={6} className={classes.loginSideBar}>
                       <img src={MU_LOGIN_SIDE_IMAGE} alt="backImg" className={classes.loginSideBarImage}/>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                       <Paper component={'form'} onSubmit={handleLogin} className={classes.gridPaper} elevation={0}>
                          <img src={MU_LOGO} alt="logo" className={classes.logo}/><br/>
                          <TextField inputRef={username} autoFocus autoCorrect="false" spellCheck={'false'}
                                     className={classes.input} variant="outlined" label="Email ID"
                                     fullWidth
                                     size="small"/>
                          <TextField inputRef={password} className={classes.input} variant="outlined" type="password"
                                     label="Password"
                                     fullWidth size="small"/>
                          <Grid container direction="row" justify="space-between" alignItems="flex-end">
                             <FormControlLabel className={classes.input}
                                               control={<Checkbox name="checkedB" color="primary"/>}
                                               label="Remember me"/>
                             <MuLoadingButton variant={'contained'} type={'submit'} loading={loginLoading}
                                              onClick={handleLogin} className={classes.button}
                                              color={'primary'}>Sign in</MuLoadingButton>
                          </Grid>
                          <br/><br/>
                          <Button fullWidth>Forget password</Button>
                       </Paper>
                    </Grid>
                 </Grid>
              </Paper>
           </Grid>
        </Grid>
     </div>
   );
}
