import { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenLogoLoader from '../components/basic/loader';
import { withSnackbar } from 'notistack';
import ActivityContainer from '../components/project/activityContainer.js';
import { LazyComponent } from '../components/basic/basic.js';
import MuLogin from './login';
import { sessionSelector } from '../redux/slicer/sessionSlice.js';
import { sessionCheckApi } from '../api/request/sessionApi.js';

const MuAdmin = lazy(() => import('./admin'));

function AppContainer () {
   const { loading, session } = useSelector(sessionSelector);
   const dispatch = useDispatch();
   
   useEffect(() => {
      dispatch(sessionCheckApi());
   }, [dispatch]);
   
   return (
     <ActivityContainer>
        {loading ? <FullScreenLogoLoader/> : <LazyComponent>{session ? <MuAdmin/> : <MuLogin/>}</LazyComponent>}
     </ActivityContainer>
   );
}

export default withSnackbar(AppContainer);
