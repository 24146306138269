import { createSlice } from '@reduxjs/toolkit';
import { objectFind, objectFindUpdate } from '../../helpers/jsHelper.js';

const taskSlice = createSlice({
   name: 'task',
   initialState: {
      callingCount:0,
      callingData: [],
      callingLoad: false,
      empList:[],
      modalLoading: false,
      modalOpen: false,
      callingSkills: {},
   },
   reducers: {
      callingData: (state, { payload=[] }) => {
         const {totalFound=0,data=[]} = payload;
         state.callingData = data;
         state.callingCount = totalFound;
         state.callingLoad = false;
      },
      setEmpList: (state, { payload=[] }) => {
         state.empList=payload
      },
      callingDataUpdate: (state, { payload }) => {
         const { _id = null, data = {} } = payload ?? {};
         if (_id) {
            const item = objectFind(state.callingData, '_id', _id);
            const { cand_name } = item;
            state.callingData = objectFindUpdate(state.callingData, '_id', _id, { ...data });
            state.callingSkills = { cand_name, data };
         }
         state.modalLoading = false;
      },
      callingCommentUpdate: (state, { payload }) => {
         const { _id = null } = payload ?? {};
         if (_id) {
            const { commentCount } = objectFind(state.callingData, '_id', _id);
            state.callingData = objectFindUpdate(state.callingData, '_id', _id, { commentCount: commentCount + 1 });
         }
      },
      callingLoad: (state, { payload }) => {
         state.callingLoad = payload;
      },
      callingModal: (state, { payload }) => {
         const { loading = false, open = null } = payload;
         state.modalLoading = loading;
         if (open) {
            state.callingSkills = {};
         }
         if (open !== null) {
            state.modalOpen = open;
         }
         if (open === false) {
            state.modalLoading = false;
         }
      },
   },
});

export const {
   callingData,
   callingDataUpdate,
   callingModal,
   callingLoad,
   callingCommentUpdate,
   setEmpList
} = taskSlice.actions;
export const taskSelector = state => state.task;
export const taskSlicer = taskSlice.reducer;
export default taskSlicer;
