const { createSlice } = require('@reduxjs/toolkit');

const candidateSlice = new createSlice({
   name: 'candidate',
   initialState: {
      formData: {},
      loading: false,
      viewLoading: true,
      data: {},
      resumeLoading: false,
      commentLoading: false,
      commentData: [],
      commentStatusData: null,
      autoLoadMaster: {},
   },
   reducers: {
      candidateFormData: (state, { payload }) => {
         state.formData = payload;
      },
      candidateEditLoad: (state, { payload }) => {
         state.loading = payload;
      },
      candidateViewLoad: (state, { payload }) => {
         state.viewLoading = payload;
      },
      candidateView: (state, { payload }) => {
         state.data = payload;
         state.viewLoading = false;
      },
      candidateResumeLoading: (state, { payload }) => {
         state.resumeLoading = payload;
      },
      candidateCommentLoading: (state, { payload }) => {
         state.commentLoading = payload;
      },
      candidateCommentData: (state, { payload }) => {
         state.commentData = payload;
         state.commentLoading = false;
      },
      commentPush: (state, { payload }) => {
         state.commentData.portal.push(payload);
      },
      commentStatusData: (state, { payload }) => {
         state.commentStatusData = payload;
      },
      addAutoLoadMaster: (state, { payload }) => {
         state.autoLoadMaster = payload;
      },
      candidateCommentUpdate:(state)=>{
            const { commentCount } = state.data;
            state.data.commentCount = commentCount + 1;
      }
   },
});

export const {
   candidateEditLoad,
   candidateCommentData,
   candidateResumeLoading,
   candidateCommentLoading,
   candidateFormData,
   candidateView,
   candidateViewLoad,
   commentPush,
   commentStatusData,
   addAutoLoadMaster,
   candidateCommentUpdate
} = candidateSlice.actions;
export const candidateSelector = state => state.candidate;
const candidateSlicer = candidateSlice.reducer;
export default candidateSlicer;
