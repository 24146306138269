import { createSlice } from '@reduxjs/toolkit';
import { arrayUpsert } from '../../helpers/jsHelper.js';

const masterSlice = (name) => createSlice({
   name,
   initialState: {
      modalLoading: false,
      loading: true,
      data: [],
   },
   reducers: {
      [`${name}Data`]: (state, { payload }) => {
         state.loading = false;
         state.data = payload;
      },
      [`${name}Upsert`]: (state, { payload: data }) => {
         const { _id = 0 } = data;
         state.data = arrayUpsert(state.data, '_id', _id, data);
         state.modalLoading = false;
         state.open = false;
         state.formData = {};
      },
      [`${name}FormData`]: (state, { payload }) => {
         const { formData = {}, modalLoading = false } = payload;
         state.open = true;
         state.formData = formData;
         state.modalLoading = modalLoading;
      },
      [`${name}Modal`]: (state, { payload }) => {
         state.open = payload;
         if (!payload) {
            state.formData = {};
            state.modalLoading = false;
         }
      },
      [`${name}ModalLoad`]: (state, { payload }) => {
         state.modalLoading = payload;
      },
      [`${name}FullLoad`]: (state, { payload }) => {
         state.modalLoading = payload;
      },
   },
});

export const {
   skillData,
   skillFormData,
   skillModal,
   skillUpsert,
   skillModalLoad,
   skillFullLoad,
} = masterSlice('skill').actions;

export const {
   employeeData,
   employeeFormData,
   employeeModal,
   employeeUpsert,
   employeeModalLoad,
   employeeFullLoad,
} = masterSlice('employee').actions;

export const {
   referenceData,
   referenceFormData,
   referenceModal,
   referenceUpsert,
   referenceModalLoad,
   referenceFullLoad,
} = masterSlice('reference').actions;

export const {
   levelData,
   levelFormData,
   levelModal,
   levelUpsert,
   levelModalLoad,
   levelFullLoad,
} = masterSlice('level').actions;

export const {
   channelData,
   channelFormData,
   channelModal,
   channelUpsert,
   channelModalLoad,
   channelFullLoad,
} = masterSlice('channel').actions;

export const {
   tagData,
   tagFormData,
   tagModal,
   tagUpsert,
   tagModalLoad,
   tagFullLoad,
} = masterSlice('tag').actions;

export const {
   noticeData,
   noticeFormData,
   noticeModal,
   noticeUpsert,
   noticeModalLoad,
   noticeFullLoad,
} = masterSlice('notice').actions;

export const {
   documentData,
   documentFormData,
   documentModal,
   documentUpsert,
   documentModalLoad,
   documentFullLoad,
} = masterSlice('document').actions;

export const {
   clientData,
   clientFormData,
   clientModal,
   clientUpsert,
   clientModalLoad,
   clientFullLoad,
} = masterSlice('client').actions;

export const {
   locationData,
   locationFormData,
   locationModal,
   locationUpsert,
   locationModalLoad,
   locationFullLoad,
} = masterSlice('location').actions;

export const {
   funAreaData,
   funAreaFormData,
   funAreaModal,
   funAreaUpsert,
   funAreaModalLoad,
   funAreaFullLoad,
} = masterSlice('funArea').actions;

export const {
   industryData,
   industryFormData,
   industryModal,
   industryUpsert,
   industryModalLoad,
   industryFullLoad,
} = masterSlice('industry').actions;


export const {
   userRoleData,
} = masterSlice('userRole').actions;



export const employeeSelector = state => state.employee;
export const skillSelector = state => state.skillMaster;
export const referenceSelector = state => state.reference;
export const levelSelector = state => state.level;
export const channelSelector = state => state.channel;
export const tagSelector = state => state.tag;
export const documentSelector = state => state.document;
export const clientSelector = state => state.client;
export const userRoleSelector = state => state.userRole;
export const noticeSelector = state => state.notice;
export const locationSelector = state => state.location;
export const funAreaSelector = state => state.funArea;
export const industrySelector = state => state.industry;

export const skillSlicer = masterSlice('skill').reducer;
export const employeeSlicer = masterSlice('employee').reducer;
export const referenceSlicer = masterSlice('reference').reducer;
export const levelSlicer = masterSlice('level').reducer;
export const channelSlicer = masterSlice('channel').reducer;
export const tagSlicer = masterSlice('tag').reducer;
export const documentSlicer = masterSlice('document').reducer;
export const clientSlicer = masterSlice('client').reducer;
export const userRoleSlicer = masterSlice('userRole').reducer;
export const noticeSlicer = masterSlice('notice').reducer;
export const locationSlicer = masterSlice('location').reducer;
export const funAreaSlicer = masterSlice('funArea').reducer;
export const industrySlicer = masterSlice('industry').reducer;
